<template>
  <div class="article">
    <transition name="slide-fade-1">
      <div class="tbl_wrap" key="tbl" v-if="selectedIndex == -1">
        <div class="tbl_option">
          <div>
            <h5>수주 현황</h5>
            <div class="input_checkbox">
              <label
                for="checkbox1"
                class="chk_box"
                :class="check_only_not_completed == true ? 'active' : '_'"
              >
                <i class="fa fa-check"></i>
              </label>
              <input type="checkbox" id="checkbox1" @click="toggleCheckBox()" />
              <label for="checkbox1" class="label_txt"
                >납품 미완료 항목만 검색</label
              >
            </div>
          </div>
          <div class="tbl_opt">
            <span>납품 요청일</span>
            <!-- <div> -->
            <div>
              <div class="input_text">
                <input
                  type="date"
                  placeholder="YYYY-MM-DD"
                  id="start_date"
                  :value="start_date"
                  @change="setStartDate($event)"
                  v-if="showSearchDate"
                />
              </div>
              <p>~</p>
              <div class="input_text">
                <input
                  type="date"
                  placeholder="YYYY-MM-DD"
                  id="end_date"
                  @change="setEndDate($event)"
                  :value="end_date"
                  v-if="showSearchDate"
                />
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
        <h6>조회수 : {{ filteredSalesList.length }}건</h6>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <colgroup class="sales_col">
              <col v-for="(n, index) in 6" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>매출처명</th>
                <th>제품명</th>
                <th>납품 요청일</th>
                <th>수주일</th>
                <th>납품여부</th>
                <th>매출액</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(sale, index) in filteredSalesList"
                :key="sale.id"
                @click="selectRow(index)"
                :class="{ active: selectedIndex == index }"
              >
                <td class="text_left">
                  {{ getCompanyInfo(sale.company_id).name || '' }}
                </td>
                <td class="text_left">{{ getFirstSalesProduct(index) }}</td>
                <td>{{ sale.delivery_date }}</td>
                <td>{{ sale.input_date }}</td>
                <td>{{ sale.completed ? 'Y' : 'N' }}</td>
                <td class="text_right">&#8361; {{ getSaleCost(index) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div class="aside_management_mode" key="aside" v-if="selectedIndex != -1">
        <div class="head">
          <button type="button" class="btn_prev" @click="selectRow(-1)">
            <h5>수주 현황</h5>
          </button>
        </div>
        <div class="aside_cont">
          <div class="input_text">
            <p class="warning">
              {{
                managementMode &&
                managementData.vat_id !=
                  getCompanyInfo(managementData.company_id).vat
                  ? getCompanyInfo(managementData.company_id).vat == 1
                    ? '※ 거래처정보 [부가세별도]로 변경되었습니다.'
                    : getCompanyInfo(managementData.company_id).vat == 2
                    ? '※ 거래처정보 [부가세포함]으로 변경되었습니다.'
                    : '※ 거래처정보 [영세율]으로 변경되었습니다.'
                  : ''
              }}
            </p>
            <label>매출처</label>
            <div class="input_search">
              <input
                type="text"
                :value="getCompanyInfo(managementData.company_id).name || ''"
                readonly
              />
              <!-- <button><i class="fa fa-search"></i></button> -->
            </div>
          </div>
          <div class="input_text">
            <label>출고일</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="managementData.output_date"
              @change="setOutputDate($event)"
              :readonly="!isPermissionOn"
            />
          </div>
          <div>
            <span>주문내역</span>
            <div class="mes_tbl_wrap">
              <table class="mes_tbl" :class="{ manage: managementMode }">
                <colgroup>
                  <col v-for="(n, index) in 14" :key="index" />
                </colgroup>
                <thead>
                  <tr>
                    <th>제품명</th>
                    <th>규격</th>
                    <th>과세여부</th>
                    <th>단위</th>
                    <th>주문수량</th>
                    <th>주문박스</th>
                    <th>총 수량</th>
                    <th>단가</th>
                    <th>공급가</th>
                    <th>부가세</th>
                    <th>할인액</th>
                    <th>매출액</th>
                    <th>수금액</th>
                    <th>출고지시</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(sales_product, index) in managementMode
                      ? manage_products
                      : sales_products"
                    :key="(sales_product.id, index)"
                  >
                    <td class="text_left">
                      {{
                        sales_product.parent_product_id != null
                          ? getProductInfo(sales_product.parent_product_id).name
                          : getProductInfo(sales_product.product_id).name || ''
                      }}
                      <br v-if="sales_product.parent_product_id != null" />
                      {{
                        sales_product.parent_product_id != null
                          ? `▶ ${getProductInfo(sales_product.product_id).name}`
                          : ''
                      }}
                    </td>
                    <td class="text_left">
                      {{
                        getProductInfo(sales_product.product_id).standard || ''
                      }}
                    </td>
                    <td>
                      {{
                        getProductInfo(sales_product.product_id).tax !=
                          sales_product.tax_id && managementMode
                          ? (sales_product.tax_id == true ? '과세' : '면세') +
                            ' -> ' +
                            (getProductInfo(sales_product.product_id).tax ==
                            true
                              ? '과세'
                              : '면세')
                          : sales_product.tax_id == true
                          ? '과세'
                          : '면세'
                      }}
                    </td>
                    <td>
                      {{
                        managementMode
                          ? getUnitName(
                              getProductInfo(sales_product.product_id)
                                .stock_unit_id,
                            )
                          : getUnitName(sales_product.unit_id) || ''
                      }}
                    </td>
                    <!-- 주문수량 -->
                    <td>
                      <input
                        type="text"
                        inputmode="decimal"
                        :value="$makeComma(sales_product.quantity)"
                        :disabled="
                          !managementMode ||
                            sales_product.parent_product_id != null ||
                            !isPermissionOn
                        "
                        @input="
                          $inputNumber($event, sales_product, 'quantity');
                          checkAndReplaceChildQuantity(sales_product);
                          sales_product.collect_value =
                            calSupplyTax(
                              managementMode
                                ? getCompanyInfo(managementData.company_id).vat
                                : managementData.vat_id,
                              managementMode
                                ? getProductInfo(sales_product.product_id).tax
                                : sales_product.tax_id,
                              $makeNumber(sales_product.quantity) *
                                getBoxQuantity(sales_product.box_unit_id),
                              sales_product.cost,
                            ).total - $makeNumber(sales_product.discount);
                        "
                      />
                      <span class="tbl_sub_info">{{
                        managementData.sales_products.find(
                          x => x.id == sales_product.id,
                        ) != undefined
                          ? $makeComma(
                              managementData.sales_products.find(
                                x => x.id == sales_product.id,
                              ).quantity,
                            )
                          : ''
                      }}</span>
                    </td>
                    <td>
                      <my-local-selectric
                        :id="`box_unit_selectric${index}`"
                        :watch="sales_product.box_unit_id"
                        :options="box_options"
                        :state="
                          !managementMode ||
                          sales_product.parent_product_id != null ||
                          !isPermissionOn
                            ? 'disabled'
                            : ''
                        "
                        :index="index"
                        @changeValue="
                          $event => {
                            $store.commit('setManagementBoxUnitIdToOutput', {
                              value: $event,
                              index: index,
                            });
                            if (
                              getProductInfo(sales_product.product_id)
                                .product_group_yn
                            ) {
                              const findParentProduct = managementOutputData.find(
                                x => x.product_id == sales_product.product_id,
                              );
                              findParentProduct.box_unit_id = $event;
                              replaceOutputChildProductQuantity(
                                findParentProduct,
                              );
                            }
                            checkAndReplaceChildQuantity(sales_product);
                            sales_product.collect_value =
                              calSupplyTax(
                                managementMode
                                  ? getCompanyInfo(managementData.company_id)
                                      .vat
                                  : managementData.vat_id,
                                managementMode
                                  ? getProductInfo(sales_product.product_id).tax
                                  : sales_product.tax_id,
                                $makeNumber(sales_product.quantity) *
                                  getBoxQuantity(sales_product.box_unit_id),
                                sales_product.cost,
                              ).total - $makeNumber(sales_product.discount);
                          }
                        "
                      >
                      </my-local-selectric>
                    </td>
                    <!-- 총수량 -->
                    <td class="text_right">
                      {{
                        $makeComma(
                          $decimalMul(
                            $makeNumber(sales_product.quantity),
                            getBoxQuantity(sales_product.box_unit_id),
                          ),
                        )
                      }}
                    </td>
                    <!-- 단가 -->
                    <td>
                      <input
                        type="text"
                        :value="
                          sales_product.parent_product_id != null
                            ? '-'
                            : $makeComma(sales_product.cost)
                        "
                        inputmode="decimal"
                        @input="
                          $inputNumberInt($event, sales_product, 'cost');
                          sales_product.collect_value =
                            calSupplyTax(
                              managementMode
                                ? getCompanyInfo(managementData.company_id).vat
                                : managementData.vat_id,
                              managementMode
                                ? getProductInfo(sales_product.product_id).tax
                                : sales_product.tax_id,
                              $makeNumber(sales_product.quantity) *
                                getBoxQuantity(sales_product.box_unit_id),
                              sales_product.cost,
                            ).total - $makeNumber(sales_product.discount);
                        "
                        :disabled="
                          !managementMode ||
                            sales_product.parent_product_id != null ||
                            !isPermissionOn
                        "
                      />
                      <span class="tbl_sub_info">{{
                        managementData.sales_products.find(
                          x => x.id == sales_product.id,
                        ) != undefined
                          ? sales_product.parent_product_id != null
                            ? '-'
                            : $makeComma(
                                managementData.sales_products.find(
                                  x => x.id == sales_product.id,
                                ).cost,
                              )
                          : ''
                      }}</span>
                    </td>
                    <!-- @input="typingCost($event, index)" -->
                    <!-- 공급가 -->
                    <td class="text_right">
                      {{
                        sales_product.parent_product_id != null
                          ? '-'
                          : `₩ ${$makeComma(
                              calSupplyTax(
                                managementMode
                                  ? $makeNumber(
                                      getCompanyInfo(managementData.company_id)
                                        .vat,
                                    )
                                  : managementData.vat_id,
                                managementMode
                                  ? getProductInfo(sales_product.product_id).tax
                                  : sales_product.tax_id,
                                $makeNumber(sales_product.quantity) *
                                  getBoxQuantity(sales_product.box_unit_id),
                                sales_product.cost,
                              ).supply,
                            )}`
                      }}
                    </td>
                    <td class="text_right">
                      {{
                        sales_product.parent_product_id != null
                          ? '-'
                          : `₩ ${$makeComma(
                              calSupplyTax(
                                managementMode
                                  ? getCompanyInfo(managementData.company_id)
                                      .vat
                                  : managementData.vat_id,
                                managementMode
                                  ? getProductInfo(sales_product.product_id).tax
                                  : sales_product.tax_id,
                                $makeNumber(sales_product.quantity) *
                                  getBoxQuantity(sales_product.box_unit_id),
                                sales_product.cost,
                              ).tax,
                            )}`
                      }}
                    </td>
                    <!-- 할인액 -->
                    <td>
                      <input
                        type="text"
                        inputmode="decimal"
                        :value="
                          sales_product.parent_product_id != null
                            ? '-'
                            : $makeComma(sales_product.discount)
                        "
                        @input="
                          $inputNumberInt($event, sales_product, 'discount');
                          sales_product.collect_value =
                            calSupplyTax(
                              managementMode
                                ? getCompanyInfo(managementData.company_id).vat
                                : managementData.vat_id,
                              managementMode
                                ? getProductInfo(sales_product.product_id).tax
                                : sales_product.tax_id,
                              $makeNumber(sales_product.quantity) *
                                getBoxQuantity(sales_product.box_unit_id),
                              sales_product.cost,
                            ).total - $makeNumber(sales_product.discount);
                          managementData.total_discount = managementMode
                            ? managementData.product_list.length > 0
                              ? calTotalPrice(
                                  managementData.product_list,
                                  $makeNumber(
                                    getCompanyInfo(managementData.company_id)
                                      .vat,
                                  ),
                                  true,
                                ).total_discount
                              : 0
                            : managementData.sales_products.length > 0
                            ? calTotalPrice(
                                managementData.sales_products,
                                managementData.vat_id,
                                false,
                              ).total_discount
                            : 0;
                        "
                        :disabled="
                          !managementMode ||
                            sales_product.parent_product_id != null ||
                            !isPermissionOn
                        "
                      />
                      <span class="tbl_sub_info">{{
                        managementData.sales_products.find(
                          x => x.id == sales_product.id,
                        ) != undefined
                          ? sales_product.parent_product_id != null
                            ? '-'
                            : $makeComma(
                                managementData.sales_products.find(
                                  x => x.id == sales_product.id,
                                ).discount,
                              )
                          : ''
                      }}</span>
                    </td>
                    <!-- 매출액 -->
                    <td>
                      <input
                        :disabled="
                          !managementMode ||
                            sales_product.parent_product_id != null ||
                            !isPermissionOn
                        "
                        type="text"
                        :value="
                          sales_product.parent_product_id != null
                            ? '-'
                            : $makeComma(
                                calSupplyTax(
                                  managementMode
                                    ? getCompanyInfo(managementData.company_id)
                                        .vat
                                    : managementData.vat_id,
                                  managementMode
                                    ? getProductInfo(sales_product.product_id)
                                        .tax
                                    : sales_product.tax_id,
                                  $makeNumber(sales_product.quantity) *
                                    getBoxQuantity(sales_product.box_unit_id),
                                  sales_product.cost,
                                ).total - $makeNumber(sales_product.discount),
                              )
                        "
                        inputmode="decimal"
                        @blur="
                          $event => {
                            $event.target.value = $makeComma(
                              calSupplyTax(
                                managementMode
                                  ? getCompanyInfo(managementData.company_id)
                                      .vat
                                  : managementData.vat_id,
                                managementMode
                                  ? getProductInfo(sales_product.product_id).tax
                                  : sales_product.tax_id,
                                $makeNumber(sales_product.quantity) *
                                  getBoxQuantity(sales_product.box_unit_id),
                                sales_product.cost,
                              ).total - $makeNumber(sales_product.discount),
                            );
                          }
                        "
                        @keydown.enter="
                          $event =>
                            reCalCost(sales_product, $event.target.value)
                        "
                        @keypress="onlyNumber($event)"
                        @input="$inputNumberIntNoTarget($event)"
                      />
                      <span class="tbl_sub_info">{{
                        managementData.sales_products.find(
                          x => x.id == sales_product.id,
                        ) != undefined
                          ? sales_product.parent_product_id != null
                            ? '-'
                            : $makeComma(
                                $decimalSub(
                                  $makeNumber(
                                    managementData.sales_products.find(
                                      x => x.id == sales_product.id,
                                    ).total_cost,
                                  ),
                                  $makeNumber(
                                    managementData.sales_products.find(
                                      x => x.id == sales_product.id,
                                    ).discount,
                                  ),
                                ),
                              )
                          : ''
                      }}</span>
                    </td>

                    <!-- 수금액 -->
                    <td
                      class="text_right"
                      v-if="managementData.collection_type_id == null"
                    >
                      {{
                        sales_product.parent_product_id != null ? '-' : `₩ 0`
                      }}
                    </td>
                    <td v-else>
                      <input
                        type="text"
                        inputmode="decimal"
                        :value="
                          sales_product.parent_product_id != null
                            ? '-'
                            : $makeComma(sales_product.collect_value)
                        "
                        @input="
                          $inputNumberInt(
                            $event,
                            sales_product,
                            'collect_value',
                          )
                        "
                        :disabled="
                          !managementMode ||
                            sales_product.parent_product_id != null
                        "
                      />
                      <span class="tbl_sub_info">{{
                        managementData.sales_products.find(
                          x => x.id == sales_product.id,
                        ) != undefined
                          ? sales_product.parent_product_id != null
                            ? '-'
                            : $makeComma(
                                managementData.sales_products.find(
                                  x => x.id == sales_product.id,
                                ).collect_value,
                              )
                          : ''
                      }}</span>
                    </td>
                    <td>
                      <button
                        v-if="sales_product.parent_product_id == null"
                        class="btn_toggle"
                        :class="{ off: !sales_product.request_out }"
                        @click="
                          sales_product.request_out = !sales_product.request_out;
                          toggleRequestOut(sales_product);
                        "
                      >
                        <i class="fa fa-check"></i>
                        <i class="fa fa-times"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <span>출고내역</span>
            <button class="btn_sub1" @click="showBarcodeSearch = true">
              {{ '제품 식별표로 출고' }}
            </button>
            <button class="btn_sub1" @click="makeManageOutputData(1)">
              {{ '선입선출' }}
            </button>
            <div class="mes_tbl_wrap">
              <table class="mes_tbl">
                <colgroup class="sales_out_list_col">
                  <col
                    v-for="(n, index) in getChildProductsList.length > 0
                      ? 10
                      : 9"
                    :key="index"
                  />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      제품명{{ systemCompany.expire_yn ? ` (유통기한)` : '' }}
                    </th>
                    <th>규격</th>
                    <th>납품처</th>
                    <th>제품 식별번호</th>
                    <th>현재고</th>
                    <th>총 주문수량</th>
                    <th v-show="getChildProductsList.length > 0">
                      세트 투입수량
                    </th>
                    <th>수량</th>
                    <!-- <th>박스</th> -->
                    <th>총 출고수량</th>
                    <th>삭제</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(output, index) in managementOutputData"
                    :key="output.id"
                  >
                    <td class="text_left">
                      {{ findInfoFromId(products, output.product_id).name }}
                      <br v-show="output.id > 0 && systemCompany.expire_yn" />
                      {{
                        output.id > 0 && systemCompany.expire_yn
                          ? ` (${getExpireDate(output)}까지)`
                          : ''
                      }}
                    </td>
                    <td class="text_left">
                      {{ findInfoFromId(products, output.product_id).standard }}
                    </td>
                    <td class="text_left">
                      {{
                        findInfoFromId(lots, output.lot_id).company_id == null
                          ? output.curr_stock == null
                            ? '-'
                            : '자체생산'
                          : findInfoFromId(
                              companys,
                              findInfoFromId(lots, output.lot_id).company_id,
                            ).name
                      }}
                    </td>
                    <td>
                      {{
                        output.curr_stock == null
                          ? '-'
                          : `P${makeProductBarcode(output)}`
                      }}
                    </td>
                    <!-- <td>{{ `L${output.product_ident}` }}</td> -->
                    <td
                      class="text_right"
                      :class="{
                        danger:
                          output.curr_stock != null &&
                          $decimalAdd(
                            output['set_quantity'] != null
                              ? $makeNumber(output.set_quantity)
                              : 0,
                            $decimalMul(
                              $makeNumber(output.quantity),
                              getBoxQuantity(output.box_unit_id),
                            ),
                          ) > output.curr_stock,
                      }"
                    >
                      <b>{{
                        output.curr_stock == null
                          ? '-'
                          : `${$makeComma(output.curr_stock)}`
                      }}</b>
                      {{
                        output.curr_stock == null
                          ? ''
                          : findInfoFromId(units, output.unit_id).name
                      }}
                    </td>
                    <!-- 총 주문수량 -->
                    <td
                      class="text_right"
                      :class="{
                        danger:
                          groupOutputByProductId.find(
                            x => $makeNumber(x.product_id) == output.product_id,
                          ).sum !=
                          groupSalesByProductId.find(
                            x => $makeNumber(x.product_id) == output.product_id,
                          ).sum,
                      }"
                    >
                      <b>{{
                        `${$makeComma(
                          groupOutputByProductId.find(
                            x => $makeNumber(x.product_id) == output.product_id,
                          ).sum,
                        )} / `
                      }}</b>
                      <b>{{
                        managementOutputData.length > 0
                          ? `${$makeComma(
                              groupSalesByProductId.find(
                                x =>
                                  $makeNumber(x.product_id) ==
                                  output.product_id,
                              ).sum,
                            )}`
                          : ''
                      }}</b>
                      {{ findInfoFromId(units, output.unit_id).name }}
                    </td>
                    <td
                      class="text_right"
                      v-show="getChildProductsList.length > 0"
                    >
                      <b>{{
                        output.set_quantity == null
                          ? '-'
                          : $makeComma(output.set_quantity)
                      }}</b>
                      {{
                        output.set_quantity == null
                          ? ''
                          : findInfoFromId(units, output.unit_id).name
                      }}
                    </td>
                    <td class="padding_zero">
                      <input
                        type="text"
                        :value="$makeComma(output.quantity)"
                        inputmode="decimal"
                        @input="
                          $inputNumber($event, output, 'quantity');
                          if (output.curr_stock == null) {
                            replaceOutputChildProductQuantity(output);
                          }
                        "
                      />
                    </td>
                    <!-- <td class="padding_zero">
                      <my-local-selectric
                        :id="`output_box_unit_selectric${index}`"
                        :watch="output.box_unit_id"
                        :options="box_options"
                        @changeValue="
                          output.box_unit_id = $event;
                          replaceOutputChildProductQuantity(output);
                        "
                      ></my-local-selectric>
                    </td> -->
                    <td
                      class="text_right"
                      :class="{
                        danger:
                          output.curr_stock != null &&
                          $decimalAdd(
                            output['set_quantity'] != null
                              ? $makeNumber(output.set_quantity)
                              : 0,
                            $decimalMul(
                              $makeNumber(output.quantity),
                              getBoxQuantity(output.box_unit_id),
                            ),
                          ) > output.curr_stock,
                      }"
                    >
                      <b>{{
                        output.curr_stock != null
                          ? $makeComma(
                              $decimalAdd(
                                output['set_quantity'] != null
                                  ? output.set_quantity
                                  : 0,
                                $decimalMul(
                                  $makeNumber(output.quantity),
                                  getBoxQuantity(output.box_unit_id),
                                ),
                              ),
                            )
                          : $makeComma(output.quantity)
                      }}</b>
                      {{
                        output.curr_stock != null
                          ? findInfoFromId(units, output.unit_id).name
                          : output.box_unit_id == null
                          ? '낱개'
                          : findInfoFromId(boxs, output.box_unit_id).name
                      }}
                    </td>
                    <td>
                      <button
                        v-show="output.curr_stock != null"
                        class="tbl_delete_btn"
                        @click="deleteOutputProduct(index)"
                      >
                        delete
                      </button>
                    </td>
                  </tr>
                  <tr class="newBtn new">
                    <td
                      class="text_left"
                      :colspan="getChildProductsList.length > 0 ? 10 : 9"
                    >
                      <button
                        @click="showProductControlSearch = true"
                        class="btn_tbl"
                      >
                        {{ '출고제품 선택' }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="date_options">
              <div class="receiving_info" style="overflow : visible">
                <div>
                  <div class="input_text">
                    <div>
                      <p class="warning">
                        {{
                          managementMode
                            ? managementData.sales_products.length > 0
                              ? '₩ ' +
                                $makeComma(
                                  calTotalPrice(
                                    managementData.sales_products,
                                    managementData.vat_id,
                                    false,
                                  ).total_cost,
                                )
                              : ''
                            : ''
                        }}
                      </p>
                    </div>
                    <label>합계액</label>
                    <input
                      type="text"
                      placeholder=""
                      readonly
                      :value="
                        managementMode
                          ? managementData.product_list.length > 0
                            ? '₩ ' +
                              $makeComma(
                                calTotalPrice(
                                  managementData.product_list,
                                  getCompanyInfo(managementData.company_id).vat,
                                  true,
                                ).total_cost,
                              )
                            : ''
                          : managementData.sales_products.length > 0
                          ? '₩ ' +
                            $makeComma(
                              calTotalPrice(
                                managementData.sales_products,
                                managementData.vat_id,
                                false,
                              ).total_cost,
                            )
                          : ''
                      "
                    />
                  </div>
                  <div class="input_text">
                    <div>
                      <p class="warning">
                        {{
                          managementMode
                            ? managementData.sales_products.length > 0
                              ? '₩ ' +
                                $makeComma(
                                  calTotalPrice(
                                    managementData.sales_products,
                                    managementData.vat_id,
                                    false,
                                  ).non_tax_total_cost,
                                )
                              : ''
                            : ''
                        }}
                      </p>
                    </div>
                    <label>비과세</label>
                    <input
                      type="text"
                      placeholder=""
                      readonly
                      :value="
                        managementMode
                          ? managementData.product_list.length > 0
                            ? '₩ ' +
                              $makeComma(
                                calTotalPrice(
                                  managementData.product_list,
                                  getCompanyInfo(managementData.company_id).vat,
                                  true,
                                ).non_tax_total_cost,
                              )
                            : ''
                          : managementData.sales_products.length > 0
                          ? '₩ ' +
                            $makeComma(
                              calTotalPrice(
                                managementData.sales_products,
                                managementData.vat_id,
                                false,
                              ).non_tax_total_cost,
                            )
                          : ''
                      "
                    />
                  </div>
                </div>
                <div>
                  <div class="input_text">
                    <div>
                      <p class="warning">
                        {{
                          managementMode
                            ? managementData.sales_products.length > 0
                              ? '₩ ' +
                                $makeComma(
                                  calTotalPrice(
                                    managementData.sales_products,
                                    managementData.vat_id,
                                    false,
                                  ).total_discount,
                                )
                              : ''
                            : ''
                        }}
                      </p>
                    </div>
                    <label>할인액</label>
                    <input
                      id="total_discount"
                      type="text"
                      placeholder=""
                      :disabled="!managementMode || !isPermissionOn"
                      :readonly="
                        !managementData.product_list.length > 0 &&
                          !managementMode
                      "
                      :value="$makeComma(managementData.total_discount)"
                      @keydown.enter="
                        managementMode
                          ? reCalDiscount(
                              $event,
                              managementData.product_list,
                              getCompanyInfo(managementData.company_id).vat,
                            )
                          : reCalDiscount(
                              $event,
                              managementData.sales_products,
                              managementData.vat_id,
                            )
                      "
                      @keypress="onlyNumber($event)"
                      inputmode="decimal"
                      @input="
                        $inputNumberInt(
                          $event,
                          managementData,
                          'total_discount',
                        )
                      "
                    />
                    <button
                      @click="
                        managementMode
                          ? reCalDiscount(
                              $event,
                              managementData.product_list,
                              getCompanyInfo(managementData.company_id).vat,
                            )
                          : reCalDiscount(
                              $event,
                              managementData.sales_products,
                              managementData.vat_id,
                            )
                      "
                      class="btn_sub2"
                      v-show="managementMode"
                    >
                      적용
                    </button>
                  </div>
                  <div class="input_text">
                    <div>
                      <p class="warning">
                        {{
                          managementMode
                            ? managementData.sales_products.length > 0
                              ? '₩ ' +
                                calTotalPrice(
                                  managementData.sales_products,
                                  managementData.vat_id,
                                  false,
                                ).tax_total_cost
                              : ''
                            : ''
                        }}
                      </p>
                    </div>
                    <label>과세</label>
                    <input
                      type="text"
                      placeholder=""
                      readonly
                      :value="
                        managementMode
                          ? managementData.product_list.length > 0
                            ? '₩ ' +
                              calTotalPrice(
                                managementData.product_list,
                                getCompanyInfo(managementData.company_id).vat,
                                true,
                              ).tax_total_cost
                            : ''
                          : managementData.sales_products.length > 0
                          ? '₩ ' +
                            calTotalPrice(
                              managementData.sales_products,
                              managementData.vat_id,
                              false,
                            ).tax_total_cost
                          : ''
                      "
                    />
                  </div>
                </div>
                <div>
                  <div class="input_text">
                    <div>
                      <p class="warning">
                        {{
                          managementMode
                            ? managementData.sales_products.length > 0
                              ? '₩ ' +
                                calTotalPrice(
                                  managementData.sales_products,
                                  managementData.vat_id,
                                  false,
                                ).sales_cost
                              : ''
                            : ''
                        }}
                      </p>
                    </div>
                    <label>매출액</label>
                    <input
                      id="sales_cost"
                      type="text"
                      placeholder=""
                      readonly
                      :value="
                        managementMode
                          ? managementData.product_list.length > 0
                            ? '₩ ' +
                              calTotalPrice(
                                managementData.product_list,
                                getCompanyInfo(managementData.company_id).vat,
                                true,
                              ).sales_cost
                            : ''
                          : managementData.sales_products.length > 0
                          ? '₩ ' +
                            calTotalPrice(
                              managementData.sales_products,
                              managementData.vat_id,
                              false,
                            ).sales_cost
                          : ''
                      "
                    />
                  </div>
                  <div>
                    <div class="input_text">
                      <div>
                        <p class="warning">
                          {{
                            managementMode
                              ? managementData.sales_products.length > 0
                                ? '₩ ' +
                                  calTotalPrice(
                                    managementData.sales_products,
                                    managementData.vat_id,
                                    false,
                                  ).sales_cost
                                : ''
                              : ''
                          }}
                        </p>
                      </div>
                      <div class="input_text">
                        <label>수금액</label>
                        <my-selectric
                          :id="'collection_type_selectric'"
                          :watch="searchType"
                          :options="collection_options"
                          :state="
                            !managementMode || !isPermissionOn ? 'disabled' : ''
                          "
                          :commit="'setManagementCollectionTypeIdToOutput'"
                        >
                        </my-selectric>
                        <input
                          v-if="managementData.collection_type_id != null"
                          id="collect_value"
                          type="text"
                          placeholder=""
                          readonly
                          :value="
                            managementMode
                              ? managementData.product_list.length > 0
                                ? '₩ ' + $makeComma(totalCollectValue)
                                : ''
                              : managementData.sales_products.length > 0
                              ? '₩ ' + $makeComma(totalCollectValue)
                              : ''
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="date_set">
                <div class="input_text">
                  <label>납품요청일</label>
                  <input
                    disabled
                    type="date"
                    id="setDate"
                    min=""
                    placeholder=""
                    :value="managementData.delivery_date"
                  />
                </div>
                <div class="input_text">
                  <label>수주일</label>
                  <input
                    disabled
                    type="date"
                    id="today"
                    placeholder=""
                    :value="managementData.input_date"
                  />
                </div>
              </div>
              <!-- <div class="input_text">
                <label>납품 완료여부</label>
                <input
                  type="text"
                  disabled
                  :value="managementData.completed == true ? 'Y' : 'N'"
                />
              </div> -->
              <div class="text_set">
                <div class="input_text">
                  <label>비고</label>
                  <textarea
                    disabled
                    type="text"
                    placeholder="수주 내용"
                    v-model="managementData.detail"
                  ></textarea>
                </div>
              </div>
              <div class="btn_wrap">
                <button
                  class="btn_sub2"
                  @click="checkValid"
                  v-show="managementMode"
                  v-if="selectedIndex != -1 && isPermissionOn"
                >
                  출고처리
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="my_isModalOpen = false"
      @OnYesClick="
        $event => {
          my_isModalOpen = false;
          submitForm($event);
        }
      "
    ></two-button-modal>
    <product-control-search
      v-if="showProductControlSearch"
      :list="filteredProductRemainControl"
      @onclose="showProductControlSearch = false"
      @onselect="appendOutputProduct($event)"
    ></product-control-search>
    <barcode-read
      v-if="showBarcodeSearch"
      :isLot="false"
      @onselect="
        $event => {
          checkBarcode($event);
          showBarcodeSearch = false;
        }
      "
      @onclose="showBarcodeSearch = false"
    >
    </barcode-read>
  </div>
</template>

<script>
import FetchMixin from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import MySelectric from '@/layouts/components/MySelectric.vue';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import { makeComma } from '@/utils/filters';
import PriceMixin from '@/mixins/price';
import FavoriteMixin from '@/mixins/favorite';
import { mapGetters } from 'vuex';
import { getExpireDate, yyyymmdd } from '@/utils/func';
import ProductControlSearch from '@/layouts/components/search-popup/ProductControlSearch';
import { groupArrayObject } from '@/utils/func.js';
import BarcodeRead from '@/layouts/components/BarcodeReadModal';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric.vue';

export default {
  mixins: [ModalMixin, SpinnerMixin, FetchMixin, PriceMixin, FavoriteMixin],
  components: {
    MySelectric,
    TwoButtonModal,
    ProductControlSearch,
    BarcodeRead,
    MyLocalSelectric,
  },
  data() {
    return {
      showProductControlSearch: false,
      showSearchDate: false,

      //delete modal
      my_modal_title: '경고',
      my_modal_content:
        '수주내역과 출고 제품 구성 및 수량이<br />일치하지 않습니다.<br /><br />그래도 출고처리 하시겠습니까?',
      my_modal_index: -1,
      my_isModalOpen: false,

      showBarcodeSearch: false,
    };
  },
  computed: {
    ...mapGetters({
      systemCompany: 'getSystemCompany',
      box_options: 'getBoxForSelectric',
      plan_sales_type_id: 'getPlanSalesType',
      managementMode: 'getManagementModeFromOutputPage',
      managementData: 'getManagementDataFromOutput',
      manage_products: 'getManagementDataProductListFromOutput',
      companys: 'getCompany',
      products: 'getProduct',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      boxs: 'getBoxsNotSorted',
      sales_products: 'getManagementDataSalesProductsFromOutput',
      sales: 'getSalesFromOutput',
      lots: 'getLot',
      lot_sales: 'getLotSalesFromOutput',
      start_date: 'getStartDateFromOutput',
      end_date: 'getEndDateFromOutput',
      date: 'getDateFromOutput',
      selectedIndex: 'getSelectedIndexFromOutput',
      check_only_not_completed: 'getCheckOnlyNotCompletedFromOutput',
      notNullProductControl: 'getNotNullProductControl',
      ProductRemainControl: 'getProductRemainControl',
      managementOutputData: 'getManagementOutputData',
      collection_options: 'getCollectionTypeForSelectricFromSalesBase',
      searchType: 'getManagementCollectionTypeIdFromOutput',
      materialTotalStock: 'getMaterialTotalStock',
    }),
    /**
     * 출고 제품들의 박스단위와 박스소요량을 기반으로 포장재의 전체 소요량을 계산
     */
    OutputBoxSumQuantity() {
      const filtered_output_data_has_box_unit_id = this.lodash
        .clonedeep(this.manage_products)
        .filter(x => x.box_unit_id != null);
      let package_material_usage_list = [];
      filtered_output_data_has_box_unit_id.map(x => {
        const box_unit = this.lodash
          .clonedeep(this.boxs)
          .find(y => y.id == x.box_unit_id);

        box_unit.r_box_unit_material_list.map(y => {
          const cal_usage = this.$decimalMul(y.quantity, x.quantity);
          if (package_material_usage_list.length > 0) {
            const foundMaterial = package_material_usage_list.find(
              z => z.material_id == y.material_id,
            );
            if (foundMaterial != undefined) {
              foundMaterial.usage_quantity = this.$decimalAdd(
                foundMaterial.usage_quantity,
                cal_usage,
              );
            } else {
              package_material_usage_list.push({
                material_id: y.material_id,
                usage_quantity: cal_usage,
              });
            }
          } else {
            package_material_usage_list.push({
              material_id: y.material_id,
              usage_quantity: cal_usage,
            });
          }
        });
      });
      return package_material_usage_list;
    },
    filteredProductRemainControl() {
      let list_ = this.lot_sales.map(x => x.id);
      let lotProduct = this.lodash.clonedeep(
        this.notNullProductControl.filter(x => list_.includes(x.lot_id)),
      );
      lotProduct = lotProduct.map(x => {
        x.company_label = this.findInfoFromId(
          this.companys,
          this.managementData.company_id,
        ).name;
        return x;
      });

      return this.ProductRemainControl.concat(lotProduct).filter(
        x =>
          !this.managementOutputData.map(y => y.id).includes(x.id) &&
          this.managementData.product_list
            .map(z => z.product_id)
            .includes(x.product_id),
      );
    },
    isOutputItemNull() {
      if (
        this.managementOutputData == undefined ||
        this.managementOutputData == null ||
        this.managementOutputData.filter(x => x.curr_stock != null).length < 1
      ) {
        return true;
      } else {
        return false;
      }
    },
    isOutputQuantityMinus() {
      if (!this.isOutputItemNull) {
        const find = this.managementOutputData.find(
          x =>
            this.$decimalAdd(
              x['set_quantity'] != null ? this.$makeNumber(x.set_quantity) : 0,
              this.calTotalQuantity(x),
            ) <= 0,
        );
        if (find != undefined) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    isOutputBoxQuantityOverThenStock() {
      let usage_list = this.lodash.clonedeep(this.OutputBoxSumQuantity);
      if (usage_list.length < 1) {
        return { state: false, over_list: [] };
      } else {
        usage_list = usage_list.filter(x => {
          const foundMaterial = this.materialTotalStock.find(
            y => y.material_id == x.material_id,
          );
          if (foundMaterial == undefined) {
            return true;
          } else if (foundMaterial.stock_quantity < x.usage_quantity) {
            x.usage_quantity = this.$decimalSub(
              x.usage_quantity,
              foundMaterial ? foundMaterial.stock_quantity : 0,
            );
            return true;
          } else {
            return false;
          }
        });
        return { state: usage_list.length > 0, over_list: usage_list };
      }
    },
    isOutputQuantityOverThenStock() {
      if (!this.isOutputItemNull) {
        const find = this.managementOutputData
          .filter(x => x.curr_stock != null)
          .find(
            x =>
              this.$decimalAdd(
                x['set_quantity'] != null
                  ? this.$makeNumber(x.set_quantity)
                  : 0,
                this.calTotalQuantity(x),
              ) > this.$makeNumber(x.curr_stock),
          );
        if (find != undefined) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    groupSalesByProductId() {
      const productListFilter = this.lodash.clonedeep(
        this.managementData.product_list,
      );
      // .filter(
      //   x =>
      //     !(
      //       this.getProductInfo(x.product_id).product_group_yn &&
      //       this.getProductInfo(x.product_id).product_group_type_id == 2
      //     ),
      // );
      const arr = this.lodash.clonedeep(
        groupArrayObject(productListFilter, 'product_id'),
      );

      let list = [];
      arr.forEach(el => {
        const mapQuantity = el.element.map(
          x =>
            this.$makeNumber(x.quantity) *
            this.$makeNumber(
              x.box_unit_id != null
                ? this.findInfoFromId(this.boxs, x.box_unit_id).quantity
                : 1,
            ),
        );
        const sumValue = mapQuantity.reduce(
          (a, b) => this.$makeNumber(a) + this.$makeNumber(b),
        );
        list.push({
          product_id: el.group_key,
          sum: this.$makeNumber(sumValue),
        });
      });
      return list.sort((a, b) => a.product_id - b.product_id);
    },
    groupOutputByProductId() {
      const arr = this.lodash.clonedeep(
        groupArrayObject(this.managementOutputData, 'product_id'),
      );

      let list = [];
      arr.forEach(el => {
        // let toNum = n => Number(String(n).replace(/,/gi, ','));
        const mapQuantity = el.element.map(x =>
          this.$decimalAdd(
            x['set_quantity'] != null ? this.$makeNumber(x.set_quantity) : 0,
            this.calTotalQuantity(x),
          ),
        );
        const sumValue = mapQuantity.reduce(
          (a, b) => this.$makeNumber(a) + this.$makeNumber(b),
        );
        list.push({
          product_id: el.group_key,
          sum: this.$makeNumber(sumValue),
        });
      });
      return list.sort((a, b) => a.product_id - b.product_id);
    },

    getChildProductsList() {
      let outputProductsSum = this.lodash
        .clonedeep(this.groupOutputByProductId)
        .filter(x => this.getProductInfo(x.product_id).product_group_yn);

      outputProductsSum = outputProductsSum.map(x => {
        return this.lodash
          .clonedeep(this.getProductInfo(x.product_id))
          .product_group_list.map(y => {
            y.product_id = y.member_product_id;
            y.quantity = this.$decimalMul(
              this.$makeNumber(y.quantity),
              this.$makeNumber(x.sum),
            );
            return y;
          });
      });

      if (outputProductsSum.length == 0) {
        return [];
      }
      outputProductsSum = outputProductsSum.reduce((a, b) => a.concat(b));

      const obj = outputProductsSum.reduce(function(rv, x) {
        (rv[x['product_id']] = rv[x['product_id']] || []).push(x);
        return rv;
      }, {});
      let list_ = [];
      for (let k in obj) {
        list_.push({
          product_id: k,
          quantity: obj[k]
            .map(x => this.$makeNumber(x.quantity))
            .reduce((a, b) => this.$decimalAdd(a, b), 0),
        });
      }
      return list_;
    },
    getTotalSalesProductsList() {
      let outputProductsSum = this.lodash
        .clonedeep(this.managementData.product_list)
        .filter(
          x =>
            !this.getProductInfo(x.product_id).product_group_yn &&
            x.parent_product_id == null,
        );

      const obj = outputProductsSum.reduce(function(rv, x) {
        (rv[x['product_id']] = rv[x['product_id']] || []).push(x);
        return rv;
      }, {});
      let list_ = [];
      for (let k in obj) {
        list_.push({
          product_id: k,
          quantity: obj[k]
            .map(x => {
              return this.$decimalMul(
                this.$makeNumber(x.quantity),
                x.box_unit_id != null
                  ? this.$makeNumber(
                      this.findInfoFromId(this.boxs, x.box_unit_id).quantity,
                    )
                  : 1,
              );
            })
            .reduce((a, b) => this.$decimalAdd(a, b), 0),
        });
      }
      return list_;
    },
    isMatchedSalesNOutput() {
      if (!this.isOutputItemNull) {
        let a = this.groupSalesByProductId;
        let b = this.groupOutputByProductId;

        if (JSON.stringify(a) == JSON.stringify(b)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isValidData() {
      if (
        !this.isOutputItemNull &&
        !this.isOutputQuantityMinus &&
        !this.isOutputQuantityOverThenStock &&
        !this.isOutputBoxQuantityOverThenStock.state
      ) {
        return true;
      } else {
        return false;
      }
    },
    filteredSalesList() {
      if (this.sales.length > 0) {
        return this.lodash
          .clonedeep(this.sales)
          .sort((a, b) => new Date(b.input_date) - new Date(a.input_date))
          .filter(
            x =>
              new Date(x.delivery_date) <= new Date(this.end_date) &&
              new Date(x.delivery_date) >= new Date(this.start_date) &&
              x.sales_type_id != 2,
          );
      } else return [];
    },
    checkCostValid() {
      const list = this.managementMode
        ? this.managementData.product_list
        : this.managementData.sales_products;

      let overList = [];

      if (list.length > 0) {
        const calPrice = this.$makeNumber(
          this.calTotalPrice(
            this.managementData.product_list,
            this.managementData.vat_id,
            false,
          ).total_cost,
        );

        if (calPrice >= 2147483647) {
          overList.push('합계액');
        }
        if (this.totalCollectValue >= 2147483647) {
          overList.push('수금액');
        }
      }
      return overList;
    },
    totalCollectValue() {
      const list = this.managementMode
        ? this.managementData.product_list
        : this.managementData.sales_products;

      return this.lodash
        .clonedeep(list)
        .map(x => this.$makeNumber(x.collect_value))
        .reduce((a, b) => this.$decimalAdd(a, b));
    },
    chkCollectionType() {
      if (this.totalCollectValue > 0) {
        if (this.searchType == null) {
          return false;
        } else return true;
      } else return true;
    },
    salesIncludeGroupProduct() {
      return (
        this.managementData.product_list.filter(
          x => x.parent_product_id != null,
        ).length > 0
      );
    },
  },
  methods: {
    calTotalQuantity(output) {
      return this.$decimalMul(
        this.$makeNumber(output.quantity),
        this.getBoxQuantity(output.box_unit_id),
      );
    },
    checkAndReplaceChildQuantity(parent_product) {
      const parent = this.findInfoFromId(
        this.products,
        parent_product.product_id,
      );

      const updateList =
        parent_product.order_yn != undefined
          ? this.managementData.product_list
          : this.managementOutputData;

      if (parent.product_group_yn && parent.product_group_type_id == 2) {
        const sumProudctQuantity = this.lodash
          .clonedeep(updateList)
          .filter(x => x.product_id == parent_product.product_id)
          .map(x =>
            this.$decimalMul(
              this.$makeNumber(x.quantity),
              this.getBoxQuantity(x.box_unit_id),
            ),
          )
          .reduce((a, b) => this.$decimalAdd(a, b), 0);

        updateList.map(x => {
          if (x.parent_product_id == parent_product.product_id) {
            x.quantity = this.$decimalMul(
              sumProudctQuantity,
              parent.product_group_list.find(
                y => y.member_product_id == x.product_id,
              ).quantity,
            );
          }
        });
      }
    },
    replaceOutputChildProductQuantity(output) {
      console.log('진입', output);
      //output의 product_group_yn로 부모제품인지 자식제품인지 확인
      const groupProductChk = this.getProductInfo(output.product_id);
      // output이 자식제품에 포함된 제품인지 확인
      const groupChildProductChk = this.getChildProductsList
        .map(x => this.$makeNumber(x.product_id))
        .includes(output.product_id);

      //출고내역 그룹제품 수정시 수량 재계산
      if (
        this.getChildProductsList.length > 0 &&
        (groupProductChk.product_group_yn || groupChildProductChk)
      ) {
        console.log('출고내역 재계산 진입');
        let groupChildProduct = groupProductChk.product_group_list.map(
          y => y.member_product_id,
        );
        let filteredManageOutputData = groupProductChk.product_group_yn
          ? this.managementOutputData.filter(x =>
              groupChildProduct.includes(x.product_id),
            )
          : this.managementOutputData.filter(
              x => x.product_id == output.product_id,
            );
        filteredManageOutputData = filteredManageOutputData.map(x => {
          x.curr_stock_temp = x.curr_stock;
          return x;
        });

        // 세트상품 수량 채우기
        let childProductList = groupProductChk.product_group_yn
          ? this.lodash
              .clonedeep(this.getChildProductsList)
              .filter(x =>
                groupChildProduct.includes(this.$makeNumber(x.product_id)),
              )
          : this.lodash
              .clonedeep(this.getChildProductsList)
              .filter(x => x.product_id == output.product_id);

        childProductList.forEach(x => {
          let findManageOutputData = filteredManageOutputData.filter(
            y => y.product_id == x.product_id,
          );
          findManageOutputData.forEach((y, index) => {
            if (y.curr_stock_temp > x.quantity) {
              y.set_quantity = x.quantity;
              y.curr_stock_temp = this.$decimalSub(
                this.$makeNumber(y.curr_stock_temp),
                this.$makeNumber(x.quantity),
              );
              x.quantity = 0;
            } else {
              y.set_quantity =
                findManageOutputData.length - 1 == index
                  ? x.quantity
                  : y.curr_stock_temp;
              x.quantity = this.$decimalSub(
                this.$makeNumber(x.quantity),
                this.$makeNumber(y.curr_stock_temp),
              );
              y.curr_stock_temp = 0;
            }
          });
        });
      }
    },

    reCalCost(row, newSalesValue) {
      const total_value =
        this.$makeNumber(newSalesValue) + this.$makeNumber(row.discount);
      console.log('total_value', total_value);
      const company_vat = this.companys.find(
        x => x.id == this.managementData.company_id,
      ).vat;
      const product_tax = row.tax_id;
      let rowQuantity = this.$decimalMul(
        this.$makeNumber(row.quantity),
        row.box_unit_id != null ? this.getBoxQuantity(row.box_unit_id) : 1,
      );
      if (rowQuantity === 0) {
        row.quantity = 1;
        rowQuantity = 1;
      }

      if (company_vat != 1 || product_tax == 2) {
        row.cost = 0;
        row.cost = this.$makeNumber(
          this.$decimalDiv(total_value, this.$makeNumber(rowQuantity)).toFixed(
            0,
          ),
        );
      } else {
        const new_total_value = this.$makeNumber(
          this.$decimalDiv(total_value, 1.1).toFixed(0),
        );
        row.cost = 0;
        row.cost = this.$makeNumber(
          this.$decimalDiv(
            new_total_value,
            this.$makeNumber(rowQuantity),
          ).toFixed(0),
        );
      }
      row.collect_value =
        this.calSupplyTax(
          this.managementMode
            ? this.getCompanyInfo(this.managementData.company_id).vat
            : this.managementData.vat_id,
          this.managementMode
            ? this.getProductInfo(row.product_id).tax
            : row.tax_id,
          this.$makeNumber(row.quantity) * this.getBoxQuantity(row.box_unit_id),
          row.cost,
        ).total - this.$makeNumber(row.discount);
    },
    getExpireDate(item) {
      const products = this.findInfoFromId(this.products, item.product_id);
      return yyyymmdd(
        new Date(
          getExpireDate(
            new Date(item.create_time),
            products.expire_year,
            products.expire_month,
            products.expire_day,
          ),
        ),
      );
    },
    checkBarcode(arg) {
      console.log(arg);
      const foundControlId = Number(arg.substr(-5));
      console.log(this.filteredProductRemainControl);
      const foundOnOutputList = this.managementOutputData.find(
        x => x.id == foundControlId,
      );
      if (foundOnOutputList != undefined) {
        this.openOneButtonModal(
          '이미 등록 되었습니다.',
          '출고내역 리스트에 해당 제품이 이미 포함되어있습니다.',
        );
        return;
      } else if (this.filteredProductRemainControl.length < 1) {
        this.openOneButtonModal(
          '출고 가능한 제품이 없습니다.',
          '제품 재고를 다시 확인해주세요',
        );
        return;
      }
      const foundItem = this.lodash
        .clonedeep(this.filteredProductRemainControl)
        .find(x => x.id == foundControlId);
      if (foundItem != undefined) {
        this.appendOutputProduct(foundItem);
      } else {
        this.openOneButtonModal(
          '출고 불가',
          '이미 출고 되었거나, 수주받은 제품이 아닙니다.',
        );
      }
    },
    toggleRequestOut(sales_product) {
      this.UPDATE_WITH_PAYLOAD(
        'UPDATE_SALES_PRODUCT_REQUEST_OUT',
        sales_product,
        '출고지시',
      );
    },
    groupProductId() {
      const arr = this.lodash.clonedeep(
        groupArrayObject(this.managementOutputData, 'product_id'),
      );

      let list = [];
      arr.forEach(el => {
        let toNum = n => Number(String(n).replace(/,/gi, ','));
        const mapQuantity = el.element.map(x => x.quantity);
        const sumValue = mapQuantity.reduce((a, b) => toNum(a) + toNum(b));
        list.push({ product_id: el.group_key, sum: sumValue });
      });
      return list;
    },
    appendOutputProduct(e) {
      const arg = this.lodash.clonedeep(e);
      arg.curr_stock = this.lodash.clonedeep(arg.quantity);
      arg.quantity = 0;
      arg.box_unit_id = null;
      arg.set_quantity = null;
      this.managementOutputData.push(arg);
      this.replaceOutputChildProductQuantity(arg);
      this.showProductControlSearch = false;
    },
    deleteOutputProduct(index) {
      console.log(index);
      const deleteProduct = this.lodash.clonedeep(
        this.managementOutputData[index],
      );
      this.managementOutputData.splice(index, 1);
      this.replaceOutputChildProductQuantity(deleteProduct);
    },
    async deleteSales(arg1) {
      console.log(arg1);
      //   console.log(arg1);
      //   if (arg1 == undefined) return;
      //   this.showSpinner();
      //   //수주 삭제
      //   this.$store
      //     .dispatch('DELETE_SALES', this.managementData.id)
      //     .then(() => {
      //       this.FETCH_SALES_TO_OUTPUT();
      //       this.selectRow(-1);
      //     })
      //     .catch(() => {
      //       this.openOneButtonModal('삭제 오류', '삭제 중 오류 발생.');
      //     })
      //     .finally(() => {
      //       this.hideSpinner();
      //       this.CloseModal();
      //     });
    },

    deleteProductListItem(index) {
      this.$store.commit(
        'deleteManagementDataProductListItemFromOutput',
        index,
      );
    },
    async selectRow(index) {
      this.$store.commit('setSelectedIndexToOutput', index);
      if (index != -1) {
        let salesList = this.lodash.clonedeep(this.filteredSalesList[index]);
        if (salesList.collection_type_id == undefined) {
          salesList.collection_type_id = null;
        }
        salesList.total_discount = this.managementMode
          ? salesList.product_list.length > 0
            ? this.calTotalPrice(
                salesList.product_list,
                this.$makeNumber(this.getCompanyInfo(salesList.company_id).vat),
                true,
              ).total_discount
            : 0
          : salesList.sales_products.length > 0
          ? this.calTotalPrice(
              salesList.sales_products,
              salesList.vat_id,
              false,
            ).total_discount
          : 0;
        salesList.output_date = yyyymmdd(new Date());
        this.$store.commit('setManagementDataToOutput', salesList);
        await this.FETCH_LOT_SALES(this.filteredSalesList[index].id);

        if (this.salesIncludeGroupProduct) {
          await this.copyGroupProduct();
        }

        await this.makeManageOutputData(0);
      } else {
        this.$store.commit('setManagementOutputData', []);
        this.showBarcodeSearch = false;
      }
    },
    copyGroupProduct() {
      let productListTemp = this.lodash
        .clonedeep(this.managementData.product_list)
        .filter(x => this.getProductInfo(x.product_id).product_group_yn);

      productListTemp = productListTemp.map((x, index) => {
        x.create_time = null;
        x.id = -1 - index;
        x.lot_id = null;
        x.curr_stock = null;

        return x;
      });
      this.$store.commit('setManagementOutputData', productListTemp);
    },
    makeManageOutputData(chk) {
      //  chk - 0:초기진입, 1:선입선출
      let list_ = this.lot_sales.map(x => x.id);
      let stockList = this.lodash.clonedeep(
        this.notNullProductControl.filter(
          x =>
            list_.includes(x.lot_id) &&
            this.groupSalesByProductId
              .map(y => this.$makeNumber(y.product_id))
              .includes(x.product_id),
        ),
      );
      // console.log('stockList 확인', stockList);
      // return;

      if (chk == 1) {
        this.$store.commit(
          'setManagementOutputData',
          this.lodash.clonedeep(
            this.managementOutputData.filter(x => x.id < 0),
          ),
        );
        stockList = stockList.concat(
          this.lodash.clonedeep(
            this.ProductRemainControl.filter(x =>
              this.groupSalesByProductId
                .map(y => Number(y.product_id))
                .includes(Number(x.product_id)),
            ),
          ),
        );
      }
      stockList = stockList.map(x => {
        x.curr_stock = x.quantity;
        x.curr_stock_temp = x.quantity;
        x.box_unit_id = null;
        x.set_quantity = null;

        return x;
      });

      stockList.sort(
        (a, b) => new Date(a.create_time) - new Date(b.create_time),
      );

      // 세트상품 채우기
      if (this.getChildProductsList.length > 0) {
        let getChildProductsList = this.lodash.clonedeep(
          this.getChildProductsList,
        );

        getChildProductsList.forEach(x => {
          if (stockList.find(y => y.product_id == x.product_id) != undefined) {
            let filteredStockList = stockList.filter(
              y => y.product_id == x.product_id,
            );
            filteredStockList.forEach((y, index) => {
              if (y.curr_stock_temp > x.quantity) {
                y.quantity = 0;
                y.set_quantity = x.quantity;
                y.curr_stock_temp = this.$decimalSub(
                  this.$makeNumber(y.curr_stock_temp),
                  this.$makeNumber(x.quantity),
                );
                x.quantity = 0;
              } else {
                y.quantity = 0;
                y.set_quantity =
                  filteredStockList.length - 1 == index
                    ? x.quantity
                    : y.curr_stock_temp;
                x.quantity = this.$decimalSub(
                  this.$makeNumber(x.quantity),
                  this.$makeNumber(y.curr_stock_temp),
                );
                y.curr_stock_temp = 0;
              }
            });
          }
        });
      }

      // 단품 채우기
      let getTotalSalesProductsList = this.lodash.clonedeep(
        this.getTotalSalesProductsList,
      );

      getTotalSalesProductsList.forEach(x => {
        if (
          stockList.find(y => y.product_id == this.$makeNumber(x.product_id)) !=
          undefined
        ) {
          let filteredStockList = stockList.filter(
            y =>
              y.product_id == this.$makeNumber(x.product_id) &&
              y.curr_stock_temp > 0,
          );
          filteredStockList.forEach((y, index) => {
            if (y.curr_stock_temp > x.quantity) {
              y.quantity = x.quantity;
              y.curr_stock_temp = this.$decimalSub(
                y.curr_stock_temp,
                x.quantity,
              );
              x.quantity = 0;
            } else {
              y.quantity =
                filteredStockList.length - 1 == index
                  ? x.quantity
                  : y.curr_stock_temp;
              x.quantity = this.$decimalSub(x.quantity, y.curr_stock_temp);
              y.curr_stock_temp = 0;
            }
          });
        }
      });

      stockList = stockList.filter(
        x =>
          !(
            (x.set_quantity == 0 && x.quantity == 0) ||
            (x.set_quantity == null && x.quantity == 0)
          ),
      );
      console.log('stockList 확인', stockList);
      this.$store.commit(
        'setManagementOutputData',
        this.managementOutputData.concat(stockList),
      );
    },

    // setSalesProductControl() {
    //   try {
    //     let list_ = this.lot_sales.map(x => x.id);
    //     let productList = this.lodash.clonedeep(
    //       this.notNullProductControl.filter(x => list_.includes(x.lot_id)),
    //     );
    //     productList = productList.map(x => {
    //       x.box_unit_id = null;
    //       return x;
    //     });
    //     let manageProductList = this.lodash
    //       .clonedeep(this.managementData.product_list)
    //       .map(x => x.product_id);
    //     console.log(manageProductList);
    //     return productList.filter(x =>
    //       manageProductList.includes(x.product_id),
    //     );
    //   } catch (error) {
    //     return [];
    //   }
    // },
    getSaleCost(index) {
      let sale_cost = 0;
      this.filteredSalesList[index].product_list.forEach(el => {
        sale_cost += el.total_cost - el.discount;
      });
      return makeComma(sale_cost);
    },
    getFirstSalesProduct(index) {
      if (this.filteredSalesList[index].product_list[0] != undefined) {
        const productCount = this.lodash
          .clonedeep(this.filteredSalesList[index].product_list)
          .filter(x => x.parent_product_id == null);

        const firstProduct = this.getProductInfo(productCount[0].product_id)
          .name;
        return productCount.length > 1
          ? firstProduct + ' 외 ' + Number(productCount.length - 1) + '개 제품'
          : firstProduct;
      } else {
        return '';
      }
    },
    async setStartDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setStartDateToOutput', e.target.value);
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '납품 요청일 조회기간 오류',
            `납품 요청일 조회기간 최후날짜(${this.end_date})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToOutput', e.target.value);
          this.selectRow(-1);
          await this.FETCH_SALES_TO_OUTPUT();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToOutput', yyyymmdd(new Date()));
      }
    },
    async setEndDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setEndDateToOutput', e.target.value);
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '납품 요청일 조회기간 오류',
            `납품 요청일 조회기간 최초날짜(${this.start_date})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToOutput', e.target.value);
          this.selectRow(-1);
          await this.FETCH_SALES_TO_OUTPUT();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToOutput', yyyymmdd(new Date()));
      }
    },
    async toggleCheckBox() {
      this.$store.commit('toggleCheckOnlyNotCompletedToOutput');
      await this.FETCH_SALES_TO_OUTPUT();
    },
    async checkValid() {
      if (this.isValidData) {
        if (this.isMatchedSalesNOutput) {
          this.submitForm();
        } else {
          this.my_isModalOpen = true;
        }
      } else {
        if (this.isOutputItemNull) {
          this.openOneButtonModal(
            '등록불가',
            '최소 하나이상의 출고 제품을 등록하여주십시오.',
          );
        } else if (this.isOutputQuantityMinus) {
          this.openOneButtonModal(
            '등록불가',
            '출고 수량은 0이거나 음수일 수 없습니다.',
          );
        } else if (this.isOutputQuantityOverThenStock) {
          this.openOneButtonModal(
            '등록불가',
            '출고 수량은 현재고량보다 클 수 없습니다.',
          );
        } else if (this.isOutputBoxQuantityOverThenStock.state) {
          this.openOneButtonModal(
            '등록불가',
            `출고에 사용될 박스의 포장재 재고가 부족합니다.<br/>
          ====================================<br/>
          ${this.isOutputBoxQuantityOverThenStock.over_list
            .map(
              x =>
                this.findInfoFromId(this.materials, x.material_id).name +
                ' > ' +
                this.$makeComma(x.usage_quantity) +
                ' ' +
                this.findInfoFromId(
                  this.units,
                  this.findInfoFromId(this.materials, x.material_id)
                    .incoming_unit_id,
                ).name +
                ' 부족',
            )
            .join('<br/>')}
          
          `,
          );
        }
      }
    },
    async submitForm() {
      console.log('submit');
      if (this.checkCostValid.length == 0) {
        const payload = this.lodash.clonedeep(this.managementData);

        payload.vat_id = this.getCompanyInfo(
          this.managementData.company_id,
        ).vat;
        let sumSupplyMoney = 0;
        let sumTaxMoney = 0;
        let sumTotalMoney = 0;
        let sumDiscountMoney = 0;
        let sumSalesMoney = 0;
        let sumTaxProductMony = 0;
        let sumTaxFreeProductMony = 0;
        await payload.product_list.forEach(el => {
          el.quantity = this.$makeNumber(el.quantity);
          el.collect_value =
            payload.collection_type_id != null
              ? this.$makeNumber(el.collect_value)
              : 0;
          el.cost = this.$makeNumber(el.cost);
          el.discount = this.$makeNumber(el.discount);
          console.log('afterDIscount', el.discount);
          el.tax_id = this.getProductInfo(el.product_id).tax;
          el.supply_value = this.calSupplyTax(
            this.getCompanyInfo(this.managementData.company_id).vat,
            this.getProductInfo(el.product_id).tax,
            el.quantity * this.getBoxQuantity(el.box_unit_id),
            el.cost,
          ).supply;
          el.tax = this.calSupplyTax(
            this.getCompanyInfo(this.managementData.company_id).vat,
            this.getProductInfo(el.product_id).tax,
            el.quantity * this.getBoxQuantity(el.box_unit_id),
            el.cost,
          ).tax;
          el.total_cost = this.calSupplyTax(
            this.getCompanyInfo(this.managementData.company_id).vat,
            this.getProductInfo(el.product_id).tax,
            el.quantity * this.getBoxQuantity(el.box_unit_id),
            el.cost,
          ).total;
          sumSupplyMoney += el.supply_value;
          sumTaxMoney += el.tax;
          sumTotalMoney += el.total_cost;
          sumDiscountMoney += el.discount;
          sumSalesMoney += el.total_cost - el.discount;
          if (this.getProductInfo(el.product_id).tax == true) {
            sumTaxProductMony += el.total_cost;
          } else {
            sumTaxFreeProductMony += el.total_cost;
          }
        });
        payload.total_collect_value =
          payload.collection_type_id != null
            ? this.$makeNumber(this.totalCollectValue)
            : 0;
        payload.sales_products = this.lodash.clonedeep(payload.product_list);
        //전표만들기
        const account = {
          output_date: payload.output_date,
          supply_value: sumSupplyMoney,
          tax: sumTaxMoney,
          total_value: sumTotalMoney,
          sales_value: sumSalesMoney,
          tax_value: sumTaxProductMony,
          tax_free_value: sumTaxFreeProductMony,
          discount: sumDiscountMoney,
          sales_id: this.managementData.id,
          detail: null,
        };
        let productOutPayload = this.lodash
          .clonedeep(this.managementOutputData)
          .filter(x => x.curr_stock != null);

        productOutPayload.forEach(x => {
          x.sales_product_id = payload.product_list.find(
            y => y.product_id == x.product_id,
          ).id;
          x.quantity = this.$decimalAdd(
            x['set_quantity'] != null ? this.$makeNumber(x.set_quantity) : 0,
            this.calTotalQuantity(x),
          );
        });
        // console.log('payload', payload);
        // console.log('account', account);
        // console.log('outputPayload', productOutPayload);
        payload.account = account;
        payload.product_out_list = productOutPayload;
        payload.box_out_list = this.lodash.clonedeep(this.OutputBoxSumQuantity);
        console.log('payload', payload);
        //     if (!this.isMinusMoney) {
        //       console.log(payload);
        this.showSpinner();
        this.$store
          .dispatch('UPDATE_SALES_ALL_COMPLETE', payload)
          .then(async () => {
            this.openOneButtonModal(
              '출고 성공',
              '성공적으로 출고처리하였습니다.',
            );
            await this.FETCH_SALES_TO_OUTPUT();
            await this.FETCH('FETCH_NOT_NULL_PRODUCT_CONTROL');
            await this.FETCH('FETCH_PRODUCT_REMAIN_CONTROL');
            this.selectRow(-1);
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal(
              '수정 실패',
              '수주 수정 중 오류가 발생했습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else if (!this.chkCollectionType) {
        this.openOneButtonModal(
          `수금구분 선택 오류`,
          '수금구분을 선택해주세요.',
        );
        return;
      } else if (this.checkCostValid.length != 0) {
        this.openOneButtonModal(
          `${this.checkCostValid} 초과`,
          '1회 출고 최대 금액은<br />20억 이상 출고할 수 없습니다.',
        );
        return;
      }
    },

    getBoxQuantity(id) {
      let hit = this.boxs.find(x => x.id == id);
      return hit != undefined ? hit.quantity : 1;
    },
    getProductInfo(id) {
      let hit = this.products.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getCompanyInfo(id) {
      let hit = this.companys.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getUnitName(id) {
      let hit = this.units.find(x => x.id == id);
      return hit != undefined ? hit.name : '';
    },
    pushNewCompany(arg) {
      this.managementData.company_id = arg.id;
      this.showCompanySearch = false;
    },

    async FETCH_SALES_TO_OUTPUT() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_SALES_TO_OUTPUT', {
          start_date: this.start_date,
          end_date: this.end_date,
          check_only_not_completed: this.check_only_not_completed,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '수주 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async FETCH_LOT_SALES(sales_id) {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_LOT_SALES_TO_OUTPUT', sales_id)
        .then(() => {})
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    makeProductBarcode(product) {
      if (product.curr_stock != null) {
        const date = yyyymmdd(new Date(product.create_time)).replace(/-/gi, '');
        const product_control_id = product.id;
        const fix5 = ('00000' + product_control_id).substr(-5);
        product.barcode_num = date + fix5;
        return date + fix5;
      } else {
        product.barcode_num = null;
        return null;
      }
    },
    setOutputDate(e) {
      if (e.target.value != '') {
        console.log('chk', e);
        this.managementData.output_date = e.target.value;
      } else {
        this.openOneButtonModal(
          '주의',
          '출고일을 삭제할 수 없습니다.<br/> 다른 날짜를 선택해주세요.',
        );
        this.managementData.output_date = yyyymmdd(new Date());
      }
    },
    // firstOut() {
    //   this.$store.commit('setManagementOutputData', []);
    //   const outProductList = this.lodash
    //     .clonedeep(this.filteredProductRemainControl)
    //     .sort(
    //       (a, b) =>
    //         Number(a.product_ident.substr(8)) -
    //         Number(b.product_ident.substr(8)),
    //     );

    //   outProductList.map(x => {
    //     if (
    //       this.managementOutputData.find(y => y.product_id == x.product_id) ==
    //       undefined
    //     ) {
    //       this.appendOutputProduct(x);
    //     }
    //   });
    // },
  },
  async created() {
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.plan_sales_type_id == undefined) {
      await this.FETCH('FETCH_SALES_TYPE', '판매 유형');
    }
    if (this.box_options.length < 2) {
      await this.FETCH('FETCH_BOX', '박스');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.collection_options.length < 2) {
      await this.FETCH('FETCH_COLLECTION_TYPE', '수금구분');
    }
    if (this.lots.length < 1) {
      await this.FETCH('FETCH_LOT_ALL');
    }
    if (this.start_date == null) {
      var date = new Date();
      this.$store.commit('setStartDateToOutput', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() + 14));
      this.$store.commit('setEndDateToOutput', yyyymmdd(date2));
      // await this.FETCH_SALES_TO_OUTPUT();
    }
    this.showSearchDate = true;
    await this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '재고 현황');
    await this.FETCH_SALES_TO_OUTPUT();
    await this.FETCH('FETCH_NOT_NULL_PRODUCT_CONTROL');
    await this.FETCH('FETCH_PRODUCT_REMAIN_CONTROL');

    if (
      this.managementData.id != undefined &&
      this.sales.find(x => this.managementData.id == x.id) == undefined
    ) {
      this.selectRow(-1);
    }

    if (this.managementData.input_date == null) {
      var date3 = new Date();
      this.managementData.input_date = yyyymmdd(date3);
      var date4 = new Date(date.setDate(date.getDate() + 7));
      this.managementData.delivery_date = yyyymmdd(date4);
    }
    this.managementData.sales_type_id = this.plan_sales_type_id.id;
  },
};
</script>

<style>
/* 애니메이션 진입 및 진출은 다른 지속 시간 및  */
/* 타이밍 기능을 사용할 수 있습니다. */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.55s ease-in-out;
}
.slide-fade-enter {
  transform: translateX(100%);
}
.slide-fade-leave-to {
  transform: translateX(100%);
}
.danger b {
  color: #f98787;
}
</style>
